import styled from 'styled-components'
import Navbar from "../Components/Navbar"
import { Calendar } from 'react-date-range';
import DateRangePicker from '@wojtekmaj/react-daterange-picker';
import { useState } from 'react';
import '@wojtekmaj/react-daterange-picker/dist/DateRangePicker.css';
import 'react-calendar/dist/Calendar.css';


const Container = styled.div`
    height: 100vh;
    width: 100vw;
    overflow: hidden;
    align-items: center;
    justify-content: center;
`
const InnerBody = styled.div`
    width: 100%;
    height: 100%;
    overflow-y: scroll;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 20px;
    font-size: var(--fsl);

    &::-webkit-scrollbar {
        width: 5px;
        background-color: #F4F6F7;
    }
    &::-webkit-scrollbar-track {
        width: 5px;
        background-color: #F4F6F7;
    }
    &::-webkit-scrollbar-thumb {
        width: 5px;
        background-color: var(--purple);
    }
`


const DateSelect = () => {

    const [value, onChange] = useState([new Date(), new Date()]);

    return (
        <Container>
            <Navbar />
            <InnerBody>
                {/* <Calendar
                    date={new Date()}
                // onChange={this.handleSelect}
                /> */}
                <DateRangePicker onChange={onChange} value={value} />

            </InnerBody>
        </Container>
    )
}

export default DateSelect