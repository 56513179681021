import React from 'react'
import { useContext, useState } from "react"
import { useNavigate } from "react-router-dom"
import styled from "styled-components"
import Button from "../Components/Button"
import Input from "../Components/Input"
import SVGBox from "../Components/SVGBox"
import { CheckForgetPassword, FetchData } from "../Components/FetchData"
import { DataContext } from "../Context/DataContext"
import { motion } from "framer-motion";


const Container = styled(motion.div)`
width: 100%;
min-height: 100%;
display: flex;
`
const Left = styled.div`
width: 40%;
display: flex;
align-items: center;
justify-content: center;
height: 100%;
`
const Right = styled.div`
width: 60%;
display: flex;
align-items: center;
height: 100%;
background-color: #ebf2fa;
border-radius: 10px;
padding: 20px 50px;
box-shadow: var(--box-shadow);
`
const Forms = styled.div`
display: flex;
align-items: center;
flex-direction: column;
width: 100%;
max-width: 300px;
gap: 20px;
`
const FormHeader = styled.div`
display: flex;
align-items: center;
width: 100%;
text-transform: uppercase;
font-weight: 600;
letter-spacing: 1px;
font-size: 19px;
color: #03045e;
`
const FormError = styled.div`
display: flex;
align-items: center;
width: 100%;
font-size: var(--font-s);
color: #f72585;
`
const FormButton = styled.div`
display: flex;
align-items: center;
width: 100%;
`
const FormInput = styled.div`
display: flex;
align-items: center;
flex-direction: column;
width: 100%;
gap: 20px;
`
const FormMsg = styled.div`
    display: flex;
    gap: 10px;
    // align-items: center;
    width: 100%;
    flex-direction: column;
    text-transform: capitalize;
    letter-spacing: var(--ls);
    font-size: var(--font-s);
    color: var(--font-color);
    text-decoration: underline;
    cursor: pointer;
`
const Text = styled.div`
    width: 100%;
    font-size: var(--font-s);
    letter-spacing: var(--ls);
`

const ForgetPassword = () => {

    const { url } = useContext(DataContext)

    const navigate = useNavigate()

    const [email, setEmail] = useState("");
    const [loading, setLoading] = useState("")
    const [message, setMessage] = useState("")
    const [otp, setOTP] = useState('')
    const [enteredOTP, setEnteredOTP] = useState(0)
    const [toShow, setToShow] = useState(1)
    const [password1, setPassword1] = useState("")
    const [password2, setPassword2] = useState("")


    const handleEmail = (e) => {
        setEmail(e.target.value)
    };

    const handleOTP = (e) => {
        setEnteredOTP(e.target.value)
    }

    const handlepassword1 = (e) => {
        setPassword1(e.target.value)
    }

    const handlepassword2 = (e) => {
        setPassword2(e.target.value)
    }

    const postEmail = async () => {

        if (email !== "") {
            setLoading(true)
            const response = await FetchData({
                "endpoint": url + "user/forget",
                data: {
                    "email": email,
                }
            })

            if (response.error === "no error") {
                setOTP(response.otp)
                setLoading(false)
                setMessage("")
                setToShow(2)
            } else {
                setMessage(response.message)
                setLoading(false)
            }
        } else {
            setMessage("Enter Valid Email ID")
            setLoading(false)
        }
    }


    const SetPassword = async () => {

        if (otp.toString() === enteredOTP) {
            const dataCheck = await CheckForgetPassword(password1, password2)

            if (dataCheck['error'] === "no error") {

                setLoading(true)
                const response = await FetchData({
                    "endpoint": url + "user/setpassword",
                    data: {
                        "email": email,
                        "password": password1
                    }
                })

                if (response.error === "no error") {
                    setLoading(false)
                    setMessage("")
                    setToShow(3)
                } else {
                    setMessage(response.message)
                    setLoading(false)
                }
            } else {
                setMessage(dataCheck["message"])
                setLoading(false)
            }
        } else {
            setMessage("Wrong OTP Entered")
            setLoading(false)
        }
    }

    const ChangeRoute = () => {
        navigate("/")
    }

    return (
        <Container
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{
                type: "spring",
                stiffness: 100,
                duration: 0.5, delay: 0.5
            }}
        >
            <Left>
                <SVGBox />
            </Left>
            <Right>
                <Forms>
                    <FormHeader>
                        Enter Email ID to Verify
                    </FormHeader>
                    <FormError>
                        {message}
                    </FormError>
                    <FormInput>
                        {toShow === 1 &&
                            <Input index={5} label={"Email ID"} onChange={handleEmail} />
                        }
                        {toShow === 2 &&
                            <>
                                <Input index={5} label={"Enter OTP"} onChange={handleOTP} />
                                <Text>
                                    Enter New Password
                                </Text>
                                <Input index={5} label={"Enter Password"} onChange={handlepassword1} />
                                <Input index={5} label={"Re Enter Password"} onChange={handlepassword2} />
                            </>
                        }
                        {toShow === 3 &&
                            <>
                                <Text>
                                    Password Reset Successfull. Please login to continue
                                </Text>
                            </>
                        }
                    </FormInput>
                    <FormButton>
                        {toShow === 1 &&
                            <Button index={6} name={loading ? "Loading..." : "Submit"} bgcolor={"var(--nav-color)"} color={"var(--main-color)"} onClick={postEmail} />
                        }
                        {toShow === 2 &&
                            <Button index={6} name={loading ? "Loading..." : "Submit"} bgcolor={"var(--nav-color)"} color={"var(--main-color)"} onClick={SetPassword} />
                        }
                        {toShow === 3 &&
                            <Button index={6} name={loading ? "Loading..." : "Continue"} bgcolor={"var(--nav-color)"} color={"var(--main-color)"} onClick={ChangeRoute} />
                        }

                    </FormButton>
                    {toShow !== 3 &&
                        <FormMsg >
                            <div onClick={() => { navigate("/") }}>
                                Already have an account? Click to Login
                            </div>
                        </FormMsg>
                    }
                </Forms>
            </Right>
        </Container>
    )
}

export default ForgetPassword