import styled from "styled-components"
import { useContext, useEffect, useState } from "react"
import { DataContext } from "../Context/DataContext"
import HashLoader from "react-spinners/HashLoader";
import 'chart.js/auto';
import { Chart } from 'react-chartjs-2';
import { Chart as ChartJS, LineController, LineElement, PointElement, LinearScale, Title } from 'chart.js';
// import { BasicTable, AccountTable, AccountTable1 } from "../Components/BasicTable";


ChartJS.register(LineController, LineElement, PointElement, LinearScale, Title);

const Container = styled.div`
    height: 100%;
    width: 100%;
    // border: 1px solid green;
`

const Container1 = styled.div`
    height: 100%;
    width: 100%;
    display: flex;
    // border: 1px solid green;
`

const Loading = styled.div`
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-style: italic;
    text-decoration: underline;
    color: var(--black);
`

const PieContainer = styled.div`
      Height: 100%;
      width: 100%;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
`
const PieChart = styled.div`
      Height: 250px;
      width: 250px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      background-color: rgba(100, 100, 111, 0.1);
      box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
`

const StockvsSalesvsInv = ({ blr, chn, hyd, mum, del, kol, dewas }) => {

    const { allData, chartColors, options } = useContext(DataContext)


    const options1 = {
        plugins: {
            legend: {
                display: true
            }
        },
        // indexAxis: 'y',

        scales: {
            x: {
                grid: {
                    display: false,
                },
                border: {
                    display: false
                },
                ticks: {
                    // For a category axis, the val is the index so the lookup via getLabelForValue is needed
                    callback: function (val, index) {
                        // Hide every 2nd tick label
                        return this.getLabelForValue(val);
                    },
                    // crossAlign: 'far',
                    font: {
                        family: "Manrope", // Add your font here to change the font of your y axis
                    }
                }
            },
            y: {
                ticks: {
                    // For a category axis, the val is the index so the lookup via getLabelForValue is needed
                    callback: function (val, index) {
                        // Hide every 2nd tick label
                        return index % 2 === 0 ? this.getLabelForValue(val) : "";
                    },
                    font: {
                        family: "Manrope", // Add your font here to change the font of your y axis
                    },
                }
            }
        }

    }

    const [chartData, setChartData] = useState({
        datasets: [],
    });

    const [chartData1, setChartData1] = useState({
        datasets: [],
    });

    useEffect(() => {

        var result = [];
        if (blr.length > 0) {
            var blr1 = { Id: 'Blr', 'Inventory': 0, 'Sales': 0, "Inv Days": 0 };
            blr1['Inventory'] = Math.round(blr.map(item => item['Total_stock']).reduce((prev, next) => prev + next))
            blr1['Sales'] = Math.round((blr.map(item => item['Net_sales_qty(last 3M)']).reduce((prev, next) => prev + next)) / 3)
            blr1['Inv Days'] = Math.round(blr.map(item => item['Total_stock']).reduce((prev, next) => prev + next)) / (Math.round((blr.map(item => item['Net_sales_qty(last 3M)']).reduce((prev, next) => prev + next)) / 3))

            result.push(blr1)
        }

        if (chn.length > 0) {
            var chn1 = { Id: 'Chn', 'Inventory': 0, 'Sales': 0, "Inv Days": 0 };
            chn1['Inventory'] = Math.round(chn.map(item => item['Total_stock']).reduce((prev, next) => prev + next))
            chn1['Sales'] = Math.round((chn.map(item => item['Total_stock']).reduce((prev, next) => prev + next)) / 3)
            chn1['Inv Days'] = chn1['Inventory'] / chn1['Sales']
            // Math.round(chn.map(item => item['Total_stock']).reduce((prev, next) => prev + next)) / (Math.round((chn.map(item => item['Total_stock']).reduce((prev, next) => prev + next)) / 3))

            result.push(chn1)
        }

        if (del.length > 0) {
            var del1 = { Id: 'Del', 'Inventory': 0, 'Sales': 0, 'Inv Days': 0 };
            del1['Inventory'] = Math.round(del.map(item => item['Total_stock']).reduce((prev, next) => prev + next))
            del1['Sales'] = Math.round((del.map(item => item['Net_sales_qty(last 3M)']).reduce((prev, next) => prev + next)) / 3)
            del1['Inv Days'] = del1['Inventory'] / del1['Sales']

            result.push(del1)
        }

        if (hyd.length > 0) {
            var hyd1 = { Id: 'Hyd', 'Inventory': 0, 'Sales': 0, "Inv Days": 0 };
            hyd1['Inventory'] = Math.round(hyd.map(item => item['Total_stock']).reduce((prev, next) => prev + next))
            hyd1['Sales'] = Math.round((hyd.map(item => item['Net_sales_qty(last 3M)']).reduce((prev, next) => prev + next)) / 3)
            hyd1['Inv Days'] = hyd1['Inventory'] / hyd1['Sales']

            result.push(hyd1)
        }

        if (kol.length > 0) {
            var kol1 = { Id: 'Kol', 'Inventory': 0, 'Sales': 0, 'Inv Days': 0 };
            kol1['Inventory'] = Math.round(kol.map(item => item['Total_stock']).reduce((prev, next) => prev + next))
            kol1['Sales'] = Math.round((kol.map(item => item['Net_sales_qty(last 3M)']).reduce((prev, next) => prev + next)) / 3)
            kol1['Inv Days'] = kol1['Inventory'] / kol1['Sales']

            result.push(kol1)
        }

        if (mum.length > 0) {
            var mum1 = { Id: 'Mum', 'Inventory': 0, 'Sales': 0, 'Inv Days': 0 };
            mum1['Inventory'] = Math.round(mum.map(item => item['Total_stock']).reduce((prev, next) => prev + next))
            mum1['Sales'] = Math.round((mum.map(item => item['Net_sales_qty(last 3M)']).reduce((prev, next) => prev + next)) / 3)
            mum1['Inv Days'] = mum1['Inventory'] / mum1['Sales']

            result.push(mum1)
        }

        if (dewas.length > 0) {
            var dewas1 = { Id: 'Dewas', 'Inventory': 0, 'Sales': 0, 'Inv Days': 0 };
            dewas1['Inventory'] = Math.round(dewas.map(item => item['Total_stock']).reduce((prev, next) => prev + next))
            dewas1['Sales'] = Math.round((dewas.map(item => item['Net_sales_qty(last 3M)']).reduce((prev, next) => prev + next)) / 3)
            // dewas1['Inv Days'] = dewas1['Inventory'] / dewas1['Sales']

            result.push(dewas1)
        }


        setChartData({
            labels: result.map((data) => data['Id']),
            datasets: [{
                label: "Inventory",
                data: result.map((data) => data['Inventory']),
                backgroundColor: chartColors[2],
                borderWidth: 0
            },
            {
                label: "Sales",
                data: result.map((data) => data['Sales']),
                backgroundColor: chartColors[7],
                borderWidth: 0
            }]
        });

        setChartData1({
            labels: result.map((data) => data['Id']),
            datasets: [{
                label: "Inventory Days",
                data: result.map((data) => data['Inv Days']),
                backgroundColor:
                    result.map((item, index) =>
                    (index % 10 === 0 ? chartColors[0] :
                        (index % 9 === 0 ? chartColors[1] :
                            (index % 8 === 0 ? chartColors[2] :
                                (index % 7 === 0 ? chartColors[3] :
                                    (index % 6 === 0 ? chartColors[4] :
                                        (index % 5 === 0 ? chartColors[5] :
                                            (index % 4 === 0 ? chartColors[6] :
                                                (index % 3 === 0 ? chartColors[7] :
                                                    (index % 2 === 0 ? chartColors[8] :
                                                        (index % 1 === 0 ? chartColors[9] :
                                                            chartColors[10]
                                                        )
                                                    )
                                                )
                                            )
                                        )
                                    )
                                )
                            )
                        )
                    ),

                    ),
                borderWidth: 0
            }]
        });

    }, [chn])



    return (
        <Container1>
            {allData.length > 0 ?
                <>
                    <Chart type='bar' options={options1} data={chartData} />
                    <PieContainer>
                        <PieChart>
                            <Chart type='doughnut' options={options} data={chartData1}
                            />
                        </PieChart>
                    </PieContainer>

                </>
                :
                <Loading>
                    <HashLoader size={28} />
                </Loading>
            }
        </Container1>

    )
}



export {
    StockvsSalesvsInv
}