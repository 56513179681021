import styled from 'styled-components'
import Navbar from '../Components/Navbar'
import { useContext } from 'react'
import { DataContext } from '../Context/DataContext'
// import { useEffect } from 'react'
// import { useState } from 'react'
// import * as XLSX from "xlsx/xlsx";
import FillrateDash from './FillrateDash'
import InventoryDash from './InventoryDash'
import Noreport from './Noreport'
import AccountDash from './AccountDash'
import { useEffect } from 'react'
import HashLoader from 'react-spinners/HashLoader'
import DateRangePicker from '@wojtekmaj/react-daterange-picker';
import '@wojtekmaj/react-daterange-picker/dist/DateRangePicker.css';
import 'react-calendar/dist/Calendar.css';


const Container = styled.div`
    height: 100vh;
    width: 100vw;
`
const Body = styled.div`
    height: calc(100vh - 50px);
    width: 100vw;
    display: flex;
`
const InnerBody = styled.div`
    width: 100%;
    overflow-y: scroll;
    font-size: var(--fsm);

    &::-webkit-scrollbar {
        width: 5px;
        background-color: #F4F6F7;
    }
    &::-webkit-scrollbar-track {
        width: 5px;
        background-color: #F4F6F7;
    }
    &::-webkit-scrollbar-thumb {
        width: 5px;
        background-color: var(--purple);
    }


    display: flex;
    flex-direction: column;
`
const SideBar = styled.div`
    height: 100%;
    width: 18%;
    background-color: var(--green);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    // justify-content: center;
    gap: 20px;
    padding-top: 100px;
    color: var(--black);
    font-size: var(--fsl);
    font-weight: 500;
    letter-spacing: 0.3px;
    box-shadow: rgba(100, 100, 111, 0.1) 0px 7px 29px 0px;
`

const Menu = styled.div`
    background-color: ${props => props.bg};
    color: ${props => props.color};
    width: 100%;
    padding: 10px;
    cursor: pointer;
`

const Loading = styled.div`
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-style: italic;
    text-decoration: underline;
    color: var(--black);
`

const DateRange = styled.div`
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 20px;
    font-style: italic;
    text-decoration: underline;
    color: var(--black);
`


const Dash = () => {

    const { mainLoader, date, setDate, setMaxDate, setMinDate, minDate, maxDate } = useContext(DataContext)

    useEffect(() => {
        if (date !== null) {
            var min = date[0].toISOString().split('T')[0]
            setMinDate(min)
            var max = date[1].toISOString().split('T')[0]
            setMaxDate(max)
        }
    }, [date, minDate, maxDate])



    useEffect(() => {
        const reloadCount = sessionStorage.getItem('reloadCount');
        if (reloadCount < 1) {
            sessionStorage.setItem('reloadCount', String(reloadCount + 1));
            window.location.reload();
        } else {
            sessionStorage.removeItem('reloadCount');
        }
    }, []);

    const { setReport, report, allData, setAllData } = useContext(DataContext)

    const handleReport = (e) => {
        setAllData([])
        setReport(e)
    }


    return (
        <Container>
            <Navbar />
            <Body>
                <SideBar>
                    <Menu bg={report === "Fill" ? "var(--black)" : ""} color={report === "Fill" ? "var(--beige)" : ""} onClick={() => handleReport("Fill")}>
                        Fill Rate
                    </Menu>
                    <Menu bg={report === "Account" ? "var(--black)" : ""} color={report === "Account" ? "var(--beige)" : ""} onClick={() => handleReport("Account")}>
                        Account Wise Sales
                    </Menu>
                    <Menu bg={report === "Inv" ? "var(--black)" : ""} color={report === "Inv" ? "var(--beige)" : ""} onClick={() => handleReport("Inv")}>
                        Inventory
                    </Menu>
                    <Menu bg={report === "DSR" ? "var(--black)" : ""} color={report === "DSR" ? "var(--beige)" : ""} onClick={() => handleReport("DSR")}>
                        DSR
                    </Menu>
                    <Menu bg={report === "LOL" ? "var(--black)" : ""} color={report === "LOL" ? "var(--beige)" : ""} onClick={() => handleReport("LOL")}>
                        LOL Report
                    </Menu>
                </SideBar>

                <InnerBody
                >
                    {mainLoader === "false" ? <>
                        {report === "Fill" &&
                            <FillrateDash />
                        }
                        {report === "Account" &&
                            <AccountDash />
                        }
                        {report === "Inv" &&
                            <InventoryDash />
                        }
                        {report === "DSR" &&
                            <Noreport />
                        }
                        {report === "LOL" &&
                            <Noreport />
                        }
                    </>
                        :
                        <Loading>
                            {mainLoader === "true" &&
                                <HashLoader size={28} />
                            }
                            {mainLoader === "nodata" &&
                                <DateRange>
                                    <div>No data to show. Change date to see values</div>
                                    <DateRangePicker onChange={setDate} value={date} />
                                </DateRange>
                            }
                        </Loading>
                    }
                </InnerBody>
            </Body>
        </Container >
    )
}

export default Dash