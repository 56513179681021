import styled from 'styled-components'
import { POLossByCat, POLossByCatChart, POLossByItem, POLossByItemChart, POLossBySite, POLossBySiteChart } from './Fillratereport'
import { useContext, useEffect } from 'react'
import { DataContext } from '../Context/DataContext'
// import { useEffect } from 'react'
import Switch from '@mui/material/Switch';
import { useState } from 'react'
// import { useEffect } from 'react'
import * as XLSX from "xlsx/xlsx";
import { BsDownload } from "react-icons/bs"
import DateRangePicker from '@wojtekmaj/react-daterange-picker';
import '@wojtekmaj/react-daterange-picker/dist/DateRangePicker.css';
import 'react-calendar/dist/Calendar.css';
import HashLoader from 'react-spinners/HashLoader';
import * as Excel from "exceljs";
import FileSaver from 'file-saver';


const label = { inputProps: { 'aria-label': 'Switch demo' } };


const Container = styled.div`
    min-height: 100%;
    width: 100%;
    display: flex;
    background-color: var(--beige1);
`
const InnerBody = styled.div`
    width: 100%;
    height: 100%;
    overflow-y: scroll;
    display: flex;
    flex-direction: column;
    gap: 20px;

    font-size: var(--fsm);

    &::-webkit-scrollbar {
        width: 5px;
        background-color: #F4F6F7;
    }
    &::-webkit-scrollbar-track {
        width: 5px;
        background-color: #F4F6F7;
    }
    &::-webkit-scrollbar-thumb {
        width: 5px;
        background-color: var(--purple);
    }
`

const KPI = styled.div`
    min-height: 150px;
    width: 100%;
    border-radius: 5px;
    padding: 20px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-bottom: 30px;
`

const ReportHead = styled.div`
    height: 30px;
    width: 100%;
    padding: 10px 0px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: var(--black);
    font-weight: 600;
    font-size: var(--fsl);
    border-radius: 2px;
    letter-spacing: var(--ls);
    text-transform: uppercase;
`

const Units = styled.div`
    min-height: 100px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
`

const KPIHead = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
`

const Unit = styled.div`
    height: 80px;
    width: 150px;
    background-color: ${props => props.bg};
    border-radius: 3px;
    box-shadow: ${props => props.bs};
    display: flex;
    flex-direction: ${props => props.fd};
    align-items: center;
    justify-content: center;
    font-size: var(--fsl);
    font-weight: 500;
    color: var(--black);
    gap: ${props => props.gap};
    letter-spacing: 0.5px;
`
const UnitHead = styled.div`
    font-size: var(--fsm);
    font-weight: 500;
    color: var(--black);
    text-transform: uppercase;
`

const UnitValue = styled.div`
    font-size: var(--fsxl);
    font-weight: 800;
    color: var(--blue);
`

const DateRange = styled.div`
    font-size: var(--fsm);
    letter-spacing: 0px;
    text-transform: capitalize;
    font-weight: 500;
    text-decoration: underline;
    font-style: italic;
`
const Name = styled.div`
    height: 30px;
    width: max-content;
    gap: 20px;
    padding: 10px 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: var(--black);
    font-weight: 600;
    font-size: var(--fsl);
    border-radius: 2px;
    letter-spacing: var(--ls);
    text-transform: uppercase;
`
const Download = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
`

const ReportsContainer = styled.div`
    width: 100%;
    height: 300px;
    border-radius: 5px;
    padding: 20px;
    display: flex;
    gap: 20px;
`
const Report = styled.div`
    width: ${props => props.width};
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
`

const Blank = styled.div`
    min-height: 100px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: var(--fsl);
    font-weight: 600;
    letter-spacing: 0.2px;
    text-transform: uppercase;
`


const FillrateDash = () => {

    const { selectedSite, allData, minDate, maxDate, date, setDate, setMinDate, setMaxDate } = useContext(DataContext)
    const [uom, setUOM] = useState(true)
    const [uom1, setUOM1] = useState(true)
    // const [minDate, setMinDate] = useState("")
    // const [maxDate, setMaxDate] = useState("")

    const [poLoss, setPOLoss] = useState([])
    const [siteLoss, setSiteLoss] = useState([])
    const [itemLoss, setItemLoss] = useState([])


    useEffect(() => {
        if (date !== null) {
            var min = date[0].toISOString().split('T')[0]
            setMinDate(min)
            var max = date[1].toISOString().split('T')[0]
            setMaxDate(max)
        }
    }, [date, minDate, maxDate])



    const toggle = () => {
        setUOM(!uom)
        setUOM1(true)
    }

    const toggle1 = () => {
        setUOM1(!uom1)
    }


    const downloadExcel = async (data, name) => {
        console.log(data)
        const workbook = new Excel.Workbook();
        const worksheet = workbook.addWorksheet('My Sheet');

        worksheet.columns = [
            { header: "id", key: "id" },
            { header: "SO number", key: "SO number" },
            { header: "Status", key: "Status" },
            { header: "Item Code", key: "Item Code" },
            { header: "SO Date", key: "SO Date" },
            { header: "Site", key: "Site" },
            { header: "Party Code", key: "Party Code" },
            { header: "Party Description", key: "Party Description" },
            { header: "Party Category", key: "Party Category" },
            { header: "Party desc.", key: "Party desc." },
            { header: "Customer PO No", key: "Customer PO No" },
            { header: "Item Description", key: "Item Description" },
            { header: "Party Group Description", key: "Party Group Description" },
            { header: "SO Qty", key: "SO Qty" },
            { header: "SO Value", key: "SO Value" },
            { header: "SO Qty in Kgs", key: "SO Qty in Kgs" },
            { header: "Inv Qty", key: "Inv Qty" },
            { header: "Inv Value", key: "Inv Value" },
            { header: "Inv Qty in Kgs", key: "Inv Qty in Kgs" },
            { header: "Qty-SO vs Inv", key: "Qty-SO vs Inv" },
            { header: "Value-SO vs Inv", key: "Value-SO vs Inv" },
            { header: "Qty in Kgs-SO vs Inv", key: "Qty in Kgs-SO vs Inv" },
        ];
        const newRows = worksheet.addRows(data);

        worksheet.eachRow({ includeEmpty: true }, function (row, rowNumber) {
            row.eachCell(function (cell, colNumber) {
                cell.font = {
                    name: 'Arial',
                    family: 2,
                    bold: false,
                    size: 10,
                };
                cell.alignment = {
                    vertical: 'middle', horizontal: 'center'
                };

                if (rowNumber == 1) {
                    row.height = 20;
                    cell.font = {
                        bold: true,
                    };
                    cell.fill = {
                        type: 'pattern',
                        pattern: 'solid',
                        fgColor: { argb: 'FFFF00' }

                    }
                }
            });
        });

        worksheet.columns.forEach(function (column, i) {
            let maxLength = 0;
            column["eachCell"]({ includeEmpty: true }, function (cell) {
                var columnLength = cell.value ? cell.value.toString().length : 10;
                if (columnLength > maxLength) {
                    maxLength = columnLength;
                }
            });
            column.width = maxLength < 10 ? 10 : maxLength;
        });
        // await workbook.xlsx.writeFile(name)
        workbook.xlsx.writeBuffer()
            .then(buffer => FileSaver.saveAs(new Blob([buffer]), name))
            .catch(err => console.log('Error writing excel export', err))
        // const worksheet = XLSX.utils.json_to_sheet(data);
        // const workbook = XLSX.utils.book_new();
        // XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
        // XLSX.writeFile(workbook, name);
    };


    // useEffect(() => {

    //     if (allData.length > 0) {
    //         const unique = [...new Set(allData.map(item => item["SO Date"]))]

    //         var dates = []
    //         for (var i = 0; i < unique.length; i++) {
    //             var obj = unique[i]

    //             var dateParts = obj.split("/");

    //             // month is 0-based, that's why we need dataParts[1] - 1
    //             var dateObject = new Date(+dateParts[2], dateParts[1] - 1, +dateParts[0]);
    //             dates.push(new Date(dateObject))
    //         }

    //         var min = dates.reduce(function (a, b) { return a < b ? a : b; });
    //         var max = dates.reduce(function (a, b) { return a > b ? a : b; });
    //         setMinDate(min)
    //         setMaxDate(max)
    //     }

    // }, [allData])



    return (
        <Container>
            <InnerBody>
                <KPI>
                    <ReportHead>
                        <KPIHead>
                            KPI

                            {allData.length > 0 &&
                                <Download onClick={() => downloadExcel(allData, "FullData.xlsx")} style={{ cursor: "pointer" }}>
                                    <BsDownload />
                                </Download>
                            }
                        </KPIHead>
                        <DateRangePicker onChange={setDate} value={date} />
                    </ReportHead>
                    <Units>
                        <Unit fd={"column"} gap={"0px"}>
                            <div style={{ fontSize: "11px", textTransform: "uppercase", color: "var(--blue)", fontWeight: "600" }}>
                                Qty <Switch {...label} defaultChecked onChange={toggle} /> Value
                            </div>
                            {!uom &&
                                <div style={{ fontSize: "11px", textTransform: "uppercase", color: "var(--blue)", fontWeight: "600" }}>
                                    Kgs <Switch {...label} defaultChecked onChange={toggle1} /> Qty
                                </div>
                            }
                        </Unit>

                        <Unit bs={"var(--bs2)"} bg={"rgba(142,236,245, 0.4)"} fd={"column"} gap={"10px"}>
                            <UnitHead>
                                SO
                            </UnitHead>

                            <UnitValue>
                                {allData.length > 0 ?
                                    uom === false ?
                                        uom1 === true ?
                                            Math.round(allData.map(item => item['SO Qty']).reduce((prev, next) => prev + next)).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                                            :
                                            Math.round(allData.map(item => item['SO Qty in Kgs']).reduce((prev, next) => prev + next)).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")

                                        :
                                        Math.round(allData.map(item => item['SO Value']).reduce((prev, next) => prev + next)).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")

                                    :
                                    "..."
                                }
                            </UnitValue>
                        </Unit>

                        <Unit bs={"var(--bs2)"} bg={"rgba(185,251,192, 0.5)"} fd={"column"} gap={"10px"}>
                            <UnitHead>
                                Invoice
                            </UnitHead>

                            <UnitValue>
                                {allData.length > 0 ?
                                    uom === false ?
                                        uom1 === true ?
                                            Math.round(allData.map(item => item['Inv Qty']).reduce((prev, next) => prev + next)).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                                            :
                                            Math.round(allData.map(item => item['Inv Qty in Kgs']).reduce((prev, next) => prev + next)).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                                        :
                                        Math.round(allData.map(item => item['Inv Value']).reduce((prev, next) => prev + next)).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")

                                    :
                                    "..."
                                }
                            </UnitValue>
                        </Unit>

                        <Unit bs={"var(--bs2)"} bg={"rgba(251,248,204,0.5)"} fd={"column"} gap={"10px"}>
                            <UnitHead>
                                Loss
                            </UnitHead>

                            <UnitValue>
                                {allData.length > 0 ?
                                    uom === false ?
                                        uom1 === true ?

                                            (Math.round(allData.map(item => item['SO Qty']).reduce((prev, next) => prev + next)) - Math.round(allData.map(item => item['Inv Qty']).reduce((prev, next) => prev + next))).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                                            :
                                            (Math.round(allData.map(item => item['SO Qty in Kgs']).reduce((prev, next) => prev + next)) - Math.round(allData.map(item => item['Inv Qty in Kgs']).reduce((prev, next) => prev + next))).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                                        :
                                        (Math.round(allData.map(item => item['SO Value']).reduce((prev, next) => prev + next)) - Math.round(allData.map(item => item['Inv Value']).reduce((prev, next) => prev + next))).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")

                                    :
                                    "..."
                                }
                            </UnitValue>
                        </Unit>


                        <Unit bs={"var(--bs2)"} bg={"rgba(255,214,165,0.5)"} fd={"column"} gap={"10px"}>
                            <UnitHead>
                                FillRate
                            </UnitHead>

                            <UnitValue>
                                {allData.length > 0 ?
                                    uom === false ?
                                        uom1 === true ?

                                            Math.round((Math.round(allData.map(item => item['Inv Qty']).reduce((prev, next) => prev + next)) / Math.round(allData.map(item => item['SO Qty']).reduce((prev, next) => prev + next))) * 100)
                                            :
                                            Math.round((Math.round(allData.map(item => item['Inv Qty in Kgs']).reduce((prev, next) => prev + next)) / Math.round(allData.map(item => item['SO Qty in Kgs']).reduce((prev, next) => prev + next))) * 100)
                                        :
                                        Math.round((Math.round(allData.map(item => item['Inv Value']).reduce((prev, next) => prev + next)) / Math.round(allData.map(item => item['SO Value']).reduce((prev, next) => prev + next))) * 100)

                                    :
                                    "..."
                                }
                            </UnitValue>
                        </Unit>

                    </Units>

                </KPI>


                {/* //////////////////////// Tables /////////////////////// */}

                <Name>
                    <span>
                        PO Loss By Category
                    </span>
                    {/* {poLoss.length > 0 &&
                        <Download onClick={() => downloadExcel(poLoss, "po_loss_by_category.xlsx")} style={{ cursor: "pointer" }}>
                            <BsDownload size={18} />
                        </Download>
                    } */}
                </Name>

                <ReportsContainer>
                    <Report width={"55%"}>
                        <POLossByCat setPOLoss={setPOLoss} />
                    </Report>
                    <Report width={"45%"}>
                        <POLossByCatChart />
                    </Report>
                </ReportsContainer>

                {selectedSite !== "Nil" &&
                    <Name>
                        <span>
                            {selectedSite} PO Loss By Site
                        </span>
                        {/* {poLoss.length > 0 &&
                            <Download onClick={() => downloadExcel(siteLoss, "po_loss_by_category_site.xlsx")} style={{ cursor: "pointer" }}>
                                <BsDownload size={18} />
                            </Download>
                        } */}
                    </Name>
                }

                {selectedSite !== "Nil" &&
                    <ReportsContainer>
                        <Report width={"50%"}>
                            <POLossBySiteChart />
                        </Report>
                        <Report width={"50%"}>
                            <POLossBySite setSiteLoss={setSiteLoss} />
                        </Report>
                    </ReportsContainer>
                }

                {selectedSite !== "Nil" &&
                    <Name>
                        <span>
                            {selectedSite} PO Loss By Item
                        </span>
                        {/* {poLoss.length > 0 &&
                            <Download onClick={() => downloadExcel(itemLoss, "po_loss_by_category_item.xlsx")} style={{ cursor: "pointer" }}>
                                <BsDownload size={18} />
                            </Download>
                        } */}
                    </Name>
                }

                {selectedSite !== "Nil" &&
                    <ReportsContainer>
                        <Report width={"55%"}>
                            <POLossByItem setItemLoss={setItemLoss} />
                        </Report>
                        <Report width={"45%"}>
                            <POLossByItemChart />
                        </Report>
                    </ReportsContainer>
                }

                {selectedSite === "Nil" &&
                    <Blank>
                        Click any channel to see details
                    </Blank>
                }

            </InnerBody>
        </Container >
    )
}

export default FillrateDash