import { Route, Routes, BrowserRouter as Router } from 'react-router-dom'
// import Fillrate from './Pages/Fillrate';
import './App.css';
import AuthContextProvider from './Context/DataContext';
import { ProtectedRoute } from './Components/ProtectedRoute';
// import Homepage from './Pages/Homepage';
import Dash from './Pages/Dash';
import { ThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import Login from './Pages/Login';
import ForgetPassword from './Pages/ForgetPassword';
import DateSelect from './Pages/DateSelect';


const theme = createMuiTheme({
  typography: {
    fontFamily: [
      'Manrope, sans-serif',
      // 'Roboto',
      // '"Helvetica Neue"',
      // 'Arial',
    ].join(','),
  }
});


function App() {
  return (
    <ThemeProvider theme={theme}>
      <AuthContextProvider>
        <Router>
          <Routes>
            <Route exact path="/" element={<Login isLogin={true} />} />
          </Routes>
          <Routes>
            <Route exact path="/signup" element={<Login isLogin={false} />} />
          </Routes>
          <Routes>
            <Route exact path="/forgetpassword" element={<ForgetPassword />} />
          </Routes>
          <Routes>
            <Route exact path="/dash" element={<ProtectedRoute><Dash /></ProtectedRoute>} />
          </Routes>
          <Routes>
            <Route exact path="/dateselect" element={<ProtectedRoute><DateSelect /></ProtectedRoute>} />
          </Routes>
          {/* <Routes>
            <Route exact path="/dash" element={<Dash />} />
          </Routes> */}
          {/* <Routes>
          <Route exact path="/fillrate" element={<Fillrate />} />
        </Routes>
        <Routes>
          <Route exact path="/dash" element={<Dash />} />
        </Routes> */}
        </Router>
      </AuthContextProvider>
    </ThemeProvider>
  );
}

export default App;
