import styled from "styled-components"
import { useContext, useEffect, useState } from "react"
import { DataContext } from "../Context/DataContext"
import HashLoader from "react-spinners/HashLoader";
import 'chart.js/auto';
import { Chart } from 'react-chartjs-2';
import { Chart as ChartJS, LineController, LineElement, PointElement, LinearScale, Title } from 'chart.js';
import { BasicTable } from "../Components/BasicTable";


ChartJS.register(LineController, LineElement, PointElement, LinearScale, Title);

const Container = styled.div`
    height: 100%;
    width: 100%;
`

const Loading = styled.div`
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-style: italic;
    text-decoration: underline;
    color: var(--black);
`

const PieContainer = styled.div`
      Height: 100%;
      width: 100%;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
`
const PieChart = styled.div`
      Height: 250px;
      width: 250px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      background-color: rgba(100, 100, 111, 0.1);
      box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
`

const POLossByCat = ({ setPOLoss }) => {

    const { setSelectedSite, allData } = useContext(DataContext)

    const [finalData, setFinalData] = useState([])


    useEffect(() => {

        var result = [];
        allData.reduce(function (res, value) {
            if (!res[value['Party Category']]) {
                res[value['Party Category']] = { Id: value['Party Category'], 'Qty-SO vs Inv': 0, 'Qty in Kgs-SO vs Inv': 0, 'Value-SO vs Inv': 0, 'SO-Value': 0, 'Inv-Value': 0 };
                result.push(res[value['Party Category']])
            }
            res[value['Party Category']]['Qty-SO vs Inv'] += Math.round(value['Qty-SO vs Inv']);
            res[value['Party Category']]['Qty in Kgs-SO vs Inv'] += Math.round(value['Qty in Kgs-SO vs Inv']);
            res[value['Party Category']]['Value-SO vs Inv'] += Math.round(value['Value-SO vs Inv']);
            res[value['Party Category']]['SO-Value'] += Math.round(value['SO Value']);
            res[value['Party Category']]['Inv-Value'] += Math.round(value['Inv Value']);
            return res;
        }, {});

        result.sort((a, b) => (a['Value-SO vs Inv'] > b['Value-SO vs Inv']) ? -1 : ((b['Value-SO vs Inv'] > a['Value-SO vs Inv']) ? 1 : 0))
        setFinalData(result)
        setPOLoss(result)

    }, [allData])


    return (
        <Container>
            {allData.length > 0 ?
                <>
                    <BasicTable finalData={finalData} selectable={true} />
                </>
                :
                <Loading>
                    <HashLoader size={28} />
                </Loading>
            }
        </Container >
    )
}


const POLossByCatChart = () => {

    const { allData, chartColors, options } = useContext(DataContext)


    const [chartData, setChartData] = useState({
        datasets: [],
    });

    useEffect(() => {

        var result = [];
        allData.reduce(function (res, value) {
            if (!res[value['Party Category']]) {
                res[value['Party Category']] = { Id: value['Party Category'], 'Value-SO vs Inv': 0 };
                result.push(res[value['Party Category']])
            }
            res[value['Party Category']]['Value-SO vs Inv'] += Math.round(value['Value-SO vs Inv']);
            return res;
        }, {});

        result.sort((a, b) => (a['Value-SO vs Inv'] > b['Value-SO vs Inv']) ? -1 : ((b['Value-SO vs Inv'] > a['Value-SO vs Inv']) ? 1 : 0))

        var res = result.slice(0, 10)

        setChartData({
            labels: res.map((data) => data['Id']),
            datasets: [{
                label: "PO Loss by Value",
                data: res.map((data) => data['Value-SO vs Inv']),
                backgroundColor:
                    res.map((item, index) =>
                    (index % 10 === 0 ? chartColors[0] :
                        (index % 9 === 0 ? chartColors[1] :
                            (index % 8 === 0 ? chartColors[2] :
                                (index % 7 === 0 ? chartColors[3] :
                                    (index % 6 === 0 ? chartColors[4] :
                                        (index % 5 === 0 ? chartColors[5] :
                                            (index % 4 === 0 ? chartColors[6] :
                                                (index % 3 === 0 ? chartColors[7] :
                                                    (index % 2 === 0 ? chartColors[8] :
                                                        (index % 1 === 0 ? chartColors[9] :
                                                            chartColors[10]
                                                        )
                                                    )
                                                )
                                            )
                                        )
                                    )
                                )
                            )
                        )
                    ),

                    ),
                borderWidth: 0
            }]
        });

    }, [allData])


    return (
        <Container>
            {allData.length > 0 ?
                <PieContainer>
                    <PieChart>
                        <Chart type='doughnut' options={options} data={chartData}
                        />
                    </PieChart>
                </PieContainer>
                :
                <Loading>
                    <HashLoader size={28} />
                </Loading>
            }
        </Container>

    )
}





const POLossBySite = ({ setSiteLoss }) => {

    const { selectedSite, allData } = useContext(DataContext)

    const [finalData, setFinalData] = useState([])
    const [loading, setLoading] = useState(false)

    useEffect(() => {

        setLoading(true)
        var result = [];

        var newArray = allData.filter(function (el) {
            return el['Party Category'] === selectedSite
        });

        if (newArray.length > 0) {
            newArray.reduce(function (res, value) {
                if (!res[value['Site']]) {
                    res[value['Site']] = { Id: value['Site'], 'Qty-SO vs Inv': 0, 'Qty in Kgs-SO vs Inv': 0, 'Value-SO vs Inv': 0, 'SO-Value': 0, 'Inv-Value': 0 };
                    result.push(res[value['Site']])
                }
                res[value['Site']]['Qty-SO vs Inv'] += Math.round(value['Qty-SO vs Inv']);
                res[value['Site']]['Qty in Kgs-SO vs Inv'] += Math.round(value['Qty in Kgs-SO vs Inv']);
                res[value['Site']]['Value-SO vs Inv'] += Math.round(value['Value-SO vs Inv']);
                res[value['Site']]['SO-Value'] += Math.round(value['SO Value']);
                res[value['Site']]['Inv-Value'] += Math.round(value['Inv Value']);

                return res;
            }, {});

            result.sort((a, b) => (a['Value-SO vs Inv'] > b['Value-SO vs Inv']) ? -1 : ((b['Value-SO vs Inv'] > a['Value-SO vs Inv']) ? 1 : 0))

            setSiteLoss(result)
            setFinalData(result)
            setLoading(false)
        }

    }, [selectedSite, allData])

    return (
        <Container>
            {selectedSite !== "Nil" ?
                <>
                    {!loading ?
                        <BasicTable finalData={finalData} selectable={false} />
                        :
                        <Loading>
                            <HashLoader size={28} />
                        </Loading>
                    }
                </>
                :
                <Loading>
                    Click any channel to get site wise details
                </Loading>
            }
        </Container >

    )
}


const POLossBySiteChart = () => {

    const { allData, chartColors, selectedSite } = useContext(DataContext)


    const options = {
        plugins: {
            legend: {
                display: false
            }
        },
        indexAxis: 'y',

        scales: {
            y: {
                grid: {
                    display: false,
                },
                border: {
                    display: false
                },

                ticks: {
                    // For a category axis, the val is the index so the lookup via getLabelForValue is needed
                    // callback: function (val, index) {
                    //     // Hide every 2nd tick label
                    //     return index % 2 === 0 ? this.getLabelForValue(val) : "";
                    // },
                    font: {
                        family: "Manrope", // Add your font here to change the font of your y axis
                    }
                }
            },
            x: {
                ticks: {
                    font: {
                        family: "Manrope", // Add your font here to change the font of your y axis
                    },
                }
            },
        }

    }

    const [chartData, setChartData] = useState({
        datasets: [],
    });

    useEffect(() => {

        var result = [];

        var newArray = allData.filter(function (el) {
            return el['Party Category'] === selectedSite
        });

        if (newArray.length > 0) {
            newArray.reduce(function (res, value) {
                if (!res[value['Site']]) {
                    res[value['Site']] = { Id: value['Site'], 'Qty-SO vs Inv': 0, 'Qty in Kgs-SO vs Inv': 0, 'Value-SO vs Inv': 0, 'SO-Value': 0, 'Inv-Value': 0 };
                    result.push(res[value['Site']])
                }
                res[value['Site']]['Value-SO vs Inv'] += Math.round(value['Value-SO vs Inv']);

                return res;
            }, {});

            result.sort((a, b) => (a['Value-SO vs Inv'] > b['Value-SO vs Inv']) ? -1 : ((b['Value-SO vs Inv'] > a['Value-SO vs Inv']) ? 1 : 0))
        }

        setChartData({
            labels: result.map((data) => data['Id']),
            datasets: [{
                label: "PO Loss by Value",
                data: result.map((data) => data['Value-SO vs Inv']),
                backgroundColor:
                    result.map((item, index) =>
                    (index % 10 === 0 ? chartColors[0] :
                        (index % 9 === 0 ? chartColors[1] :
                            (index % 8 === 0 ? chartColors[2] :
                                (index % 7 === 0 ? chartColors[3] :
                                    (index % 6 === 0 ? chartColors[4] :
                                        (index % 5 === 0 ? chartColors[5] :
                                            (index % 4 === 0 ? chartColors[6] :
                                                (index % 3 === 0 ? chartColors[7] :
                                                    (index % 2 === 0 ? chartColors[8] :
                                                        (index % 1 === 0 ? chartColors[9] :
                                                            chartColors[10]
                                                        )
                                                    )
                                                )
                                            )
                                        )
                                    )
                                )
                            )
                        )
                    ),

                    ),
                borderWidth: 0
            }]
        });

    }, [allData, selectedSite])


    return (
        <Container>
            {allData.length > 0 ?
                <Chart type='bar' options={options} data={chartData}
                />
                :
                <Loading>
                    <HashLoader size={28} />
                </Loading>
            }
        </Container>

    )
}



const POLossByItem = ({ setItemLoss }) => {

    const { selectedSite, allData } = useContext(DataContext)

    const [finalData, setFinalData] = useState([])
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        console.log(allData)
    }, [allData])

    useEffect(() => {

        setLoading(true)
        var result = [];

        var newArray = allData.filter(function (el) {
            return el['Party Category'] === selectedSite
        });

        if (newArray.length > 0) {
            newArray.reduce(function (res, value) {
                if (!res[value['Item Code']]) {
                    res[value['Item Code']] = { Id: value['Item Code'], 'Qty-SO vs Inv': 0, 'Qty in Kgs-SO vs Inv': 0, 'Value-SO vs Inv': 0, 'SO-Value': 0, 'Inv-Value': 0 };
                    result.push(res[value['Item Code']])
                }
                res[value['Item Code']]['Qty-SO vs Inv'] += Math.round(value['Qty-SO vs Inv']);
                res[value['Item Code']]['Qty in Kgs-SO vs Inv'] += Math.round(value['Qty in Kgs-SO vs Inv']);
                res[value['Item Code']]['Value-SO vs Inv'] += Math.round(value['Value-SO vs Inv']);
                res[value['Item Code']]['SO-Value'] += Math.round(value['SO Value']);
                res[value['Item Code']]['Inv-Value'] += Math.round(value['Inv Value']);

                return res;
            }, {});

            result.sort((a, b) => (a['Value-SO vs Inv'] > b['Value-SO vs Inv']) ? -1 : ((b['Value-SO vs Inv'] > a['Value-SO vs Inv']) ? 1 : 0))

            setFinalData(result)
            setItemLoss(result)
            setLoading(false)
        }

    }, [selectedSite, allData])

    return (
        <Container>
            {selectedSite !== "Nil" ?
                <>
                    {!loading ?
                        <BasicTable finalData={finalData} selectable={false} />
                        :
                        <Loading>
                            <HashLoader size={28} />
                        </Loading>
                    }
                </>
                :
                <Loading>
                    Click any channel to get product wise details
                </Loading>
            }
        </Container >

    )
}



const POLossByItemChart = () => {

    const { allData, chartColors, selectedSite } = useContext(DataContext)


    const options = {
        plugins: {
            legend: {
                display: false
            }
        },
        // indexAxis: 'y',

        scales: {
            x: {
                grid: {
                    display: false,
                },
                border: {
                    display: false
                },

                ticks: {
                    // For a category axis, the val is the index so the lookup via getLabelForValue is needed
                    callback: function (val, index) {
                        // Hide every 2nd tick label
                        return this.getLabelForValue(val).substring(0, 12) + "...";
                    },
                    crossAlign: 'far',
                    font: {
                        family: "Manrope", // Add your font here to change the font of your y axis
                    },
                    legend: {
                    }
                }
            },
            y: {
                ticks: {
                    // For a category axis, the val is the index so the lookup via getLabelForValue is needed
                    callback: function (val, index) {
                        // Hide every 2nd tick label
                        return index % 2 === 0 ? this.getLabelForValue(val) : "";
                    },
                    font: {
                        family: "Manrope", // Add your font here to change the font of your y axis
                    },
                }
            }
        }

    }

    const [chartData, setChartData] = useState({
        datasets: [],
    });

    useEffect(() => {

        var result = [];

        var newArray = allData.filter(function (el) {
            return el['Party Category'] === selectedSite
        });

        if (newArray.length > 0) {
            newArray.reduce(function (res, value) {
                if (!res[value['Item Code']]) {
                    res[value['Item Code']] = { Id: value['Item Code'], 'Qty-SO vs Inv': 0, 'Qty in Kgs-SO vs Inv': 0, 'Value-SO vs Inv': 0, 'SO-Value': 0, 'Inv-Value': 0 };
                    result.push(res[value['Item Code']])
                }
                res[value['Item Code']]['Value-SO vs Inv'] += Math.round(value['Value-SO vs Inv']);

                return res;
            }, {});

            result.sort((a, b) => (a['Value-SO vs Inv'] > b['Value-SO vs Inv']) ? -1 : ((b['Value-SO vs Inv'] > a['Value-SO vs Inv']) ? 1 : 0))
        }

        var res = result.slice(0, 10)


        setChartData({
            labels: res.map((data) => data['Id']),
            datasets: [{
                label: "PO Loss by Value",
                data: res.map((data) => data['Value-SO vs Inv']),
                backgroundColor:
                    res.map((item, index) =>
                    (index % 10 === 0 ? chartColors[0] :
                        (index % 9 === 0 ? chartColors[1] :
                            (index % 8 === 0 ? chartColors[2] :
                                (index % 7 === 0 ? chartColors[3] :
                                    (index % 6 === 0 ? chartColors[4] :
                                        (index % 5 === 0 ? chartColors[5] :
                                            (index % 4 === 0 ? chartColors[6] :
                                                (index % 3 === 0 ? chartColors[7] :
                                                    (index % 2 === 0 ? chartColors[8] :
                                                        (index % 1 === 0 ? chartColors[9] :
                                                            chartColors[10]
                                                        )
                                                    )
                                                )
                                            )
                                        )
                                    )
                                )
                            )
                        )
                    ),

                    ),
                borderWidth: 0
            }]
        });

    }, [allData, selectedSite])


    return (
        <Container>
            {allData.length > 0 ?
                <Chart type='bar' options={options} data={chartData}
                />
                :
                <Loading>
                    <HashLoader size={28} />
                </Loading>
            }
        </Container>

    )
}




export {
    POLossByCat, POLossByCatChart, POLossBySite, POLossBySiteChart, POLossByItem, POLossByItemChart
}