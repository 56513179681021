import { useContext, useState } from "react"
import { useNavigate } from "react-router-dom"
import styled from "styled-components"
import { FetchData, CheckSignUp, CheckLogin } from "../Components/FetchData"
import { useEffect } from "react"
import Button from "../Components/Button"
import Input from "../Components/Input"
// import SVGBox from "../Components/SVGBox"
import { DataContext } from "../Context/DataContext"
import Navbar from "../Components/Navbar";


const Container = styled.div`
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
`

const Body = styled.div`
    height: calc(100vh - 50px);
    width: 100vw;
    display: flex;
`
const Left = styled.div`
    width: 40%;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
`
const Right = styled.div`
    width: 60%;
    display: flex;
    align-items: center;
    height: 100%;
    background-color: var(--green);
    padding: 20px 50px;
    box-shadow: var(--bs);
`
const Forms = styled.div`
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 100%;
    max-width: 300px;
    gap: 20px;
`
const FormHeader = styled.div`
    display: flex;
    align-items: center;
    width: 100%;
    text-transform: uppercase;
    font-weight: 900;
    letter-spacing: 1px;
    font-size: 19px;
    color: var(--black);
`
const FormError = styled.div`
    display: flex;
    align-items: center;
    width: 100%;
    font-size: var(--fsm);
    color: #f72585;
    font-weight: 600;
`
const FormButton = styled.div`
    display: flex;
    align-items: center;
    width: 100%;
`
const FormInput = styled.div`
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 100%;
    gap: 20px;
`
const FormMsg = styled.div`
    display: flex;
    gap: 10px;
    // align-items: center;
    width: 100%;
    flex-direction: column;
    text-transform: capitalize;
    font-size: var(--fsl);
    font-weight: 600;
    color: #1c1c1c;
    cursor: pointer;
    gap: 20px;
`


const icon = {
    hidden: {
        pathLength: 0,
        fill: "rgba(28,28,28, 0)"
    },
    visible: {
        pathLength: 1,
        fill: "rgba(28,28,28, 1)"
    }
}


const Login = ({ isLogin }) => {

    const { url1 } = useContext(DataContext)

    const initialLogin = {
        userName: "",
        passWord: "",
    };

    const initialSignUp = {
        userName: "",
        emailID: "",
        setPassword: "",
        confirmPassword: "",
    };

    const login = isLogin

    const navigate = useNavigate()

    const [loginValue, setLoginValue] = useState(initialLogin);
    const [signUpValue, setSignUpValue] = useState(initialSignUp);

    // eslint-disable-next-line
    const [designation, setDesignation] = useState("");
    // eslint-disable-next-line
    const [accessLevel, setAccessLevel] = useState("");

    const [message, setMessage] = useState("");
    const [loading, setLoading] = useState(false);


    const handleLoginChange = (e) => {
        const { name, value } = e.target;

        setLoginValue({
            ...loginValue,
            [name]: value,
        });
    };

    const handleSignUpChange = (e) => {
        const { name, value } = e.target;

        setSignUpValue({
            ...signUpValue,
            [name]: value,
        });
    };


    const postLogin = async () => {

        setLoading(true)
        const dataCheck = await CheckLogin(loginValue)

        if (dataCheck['error'] === "no error") {

            const response = await FetchData({
                "endpoint": url1 + "user/login",
                data: {
                    "loginValue": loginValue,
                }
            })

            if (response.error === "no error" && response.message === "success") {
                localStorage.setItem("user", "admin")
                setLoading(false)
                navigate("/dash")
            } else if (response.error === "error" && response.message === "pending approval") {
                setLoading(false)
                navigate("/logged")
            } else {
                setLoading(false)
                setMessage(response.message)
            }

        } else {
            setMessage(dataCheck["message"])
            setLoading(false)
        }
    }


    const postSignup = async () => {

        setLoading(true)
        const dataCheck = await CheckSignUp(signUpValue, designation, accessLevel)

        if (dataCheck['error'] === "no error") {

            const response = await FetchData({
                "endpoint": url1 + "user/add",
                data: {
                    "signUpValue": signUpValue,
                    "designation": designation,
                    "accessLevel": accessLevel
                }
            })

            if (response.error === "no error" && response.message === "Auto Approved") {
                setLoading(false)
                navigate("/success")
            } else if (response.error === "no error" && response.message === "Pending Approval") {
                setLoading(false)
                navigate("/logged")
            } else {
                setMessage(response.message)
                setLoading(false)
            }

        } else {
            setMessage(dataCheck["message"])
            setLoading(false)
        }
    }


    useEffect(() => {

        const reloadCount = sessionStorage.getItem('reloadCount');
        if (reloadCount < 1) {
            sessionStorage.setItem('reloadCount', String(reloadCount + 1));
            localStorage.removeItem("user")
            window.location.reload();
        } else {
            sessionStorage.removeItem('reloadCount');
        }
    }, []);

    return (
        <Container
        >
            <Navbar />
            <Body>
                <Left>
                    {/* <SVGBox /> */}

                    <div className="container" style={{ height: "100px", width: "100px" }} >
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 100 100"
                            className="item"
                        >
                            <path
                                d="M0 100V0l50 50 50-50v100L75 75l-25 25-25-25z"
                                variants={icon}
                                initial="hidden"
                                animate="visible"
                                transition={{
                                    default: { duration: 1, ease: "easeInOut" },
                                    fill: { duration: 1, ease: [1, 0, 0.8, 1] }
                                }}
                            />
                        </svg>
                    </div>                </Left>
                <Right>
                    <Forms>
                        <FormHeader>
                            {login ?
                                <>
                                    Log In to your account
                                </>
                                :
                                <>
                                    Account Sign Up
                                </>
                            }
                        </FormHeader>
                        <FormError>
                            {message}
                            {/* *Incorrect details entered. Please check */}
                        </FormError>
                        <FormInput>
                            {login ?
                                <>
                                    <Input index={5} label={"User Name"} onChange={handleLoginChange}
                                        name={"userName"} width={"90%"}
                                    />
                                    <Input index={6} label={"PassWord"} onChange={handleLoginChange}
                                        name={"passWord"} type={"password"} width={"90%"} />
                                </>
                                :
                                <>
                                    <Input index={5} label={"User Name"} onChange={handleSignUpChange}
                                        name={"userName"} width={"90%"} />
                                    <Input index={6} label={"Email ID"} onChange={handleSignUpChange}
                                        name={"emailID"} width={"90%"} />
                                    <Input index={9} label={"Set Password"} onChange={handleSignUpChange}
                                        name={"setPassword"} width={"90%"} />
                                    <Input index={10} label={"Confirm Password"} onChange={handleSignUpChange}
                                        name={"confirmPassword"} width={"90%"} />
                                </>
                            }
                        </FormInput>
                        <FormButton>
                            {login ?
                                <Button index={7} name={loading ? "Loading..." : "submit"} bgcolor={"#4361ee"} color={"var(--beige)"} onClick={postLogin} />
                                :
                                <Button index={11} name={loading ? "Loading..." : "create account"} bgcolor={"#4361ee"} color={"var(--beige)"} onClick={postSignup} />
                            }
                        </FormButton>
                        <FormMsg >
                            {/* {login ?
                                <div onClick={() => { navigate("/signup") }}>
                                    New User? Click to Sign up
                                </div>
                                :
                                <div onClick={() => { navigate("/") }}>
                                    Already have an account? Click to Login
                                </div>
                            }
                            <div onClick={() => { navigate("/forgetpassword") }}>
                                Forget Password? Click here
                            </div> */}
                        </FormMsg>
                    </Forms>
                </Right>
            </Body>
        </Container>
    )
}

export default Login