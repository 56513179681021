import styled from 'styled-components'
import { motion } from "framer-motion"
import { useEffect } from 'react';

const InputBox = styled(motion.div)`
    position: relative;
    width: ${props => props.width};
    margin: auto;
    font-size: var(--fsm);
`;

const InputContainer = styled.input`
&::-webkit-outer-spin-button,
&::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
    width: 100%;
    padding: 8px;
    border: 1px solid rgba(43,43,43,0.2);
    border-radius: 5px;
    outline: none;
    transition: 0.25s;
    border: 2px solid #ffd166;
`;

const Span = styled.span`
    position: absolute;
    left: 0;
    text-transform: uppercase;
    padding: 10px;
    font-size: var(--fsm); 
    pointer-events: none;
    transition: 0.25s;
    color: rgb(43,43,43);
    letter-spacing: 1px;
    font-weight: 500;

    background-color: #ffd166;
    transform: translateX(10px) translateY(-7px);
    font-size: var(--fsm);
    padding: 0 12px;
    color: rgba(43,43,43);
    letter-spacing: 1px;
    border-radius: 2px;
    font-weight: 500;
    text-transform: uppercase;

`;


const Input = ({ type, onChange, onClick, name, label, index, value, width }) => {


    return (
        <InputBox
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ type: "spring", stiffness: 100, delay: 0.1 * index }}
            width={width}

        >
            <InputContainer defaultValue={value} type={type} label={label} name={name} required='required' onChange={onChange} onClick={onClick} />
            <Span>{label}</Span>
        </InputBox>
    )
}

export default Input